'use client'
import classNames from 'classnames'
import Footer from '../Footer'
import Header from '../Header'
import Layout from '../Layout'

interface LayoutProps {
  renderCover?: () => React.ReactNode
  mainClassName?: string
  backgroundColor?: string
  children: React.ReactNode
}

const AppLayout: React.FunctionComponent<LayoutProps> = ({
  renderCover,
  mainClassName,
  backgroundColor,
  children,
}) => {
  return (
    <Layout
      backgroundColor={backgroundColor}
      renderHeader={(isMobileMenuOpen, closeMobileMenu) => (
        <Header
          isMobileMenuOpen={isMobileMenuOpen}
          closeMobileMenu={closeMobileMenu}
        />
      )}
      renderMain={(isMobileMenuOpen) => (
        <main
          className={classNames('relative', { '!hidden': isMobileMenuOpen })}
        >
          {renderCover && renderCover()}
          <div
            className={classNames(
              'relative px-5 lg:px-0 lg:max-w-7xl mx-auto min-h-screen',
              mainClassName,
            )}
          >
            {children}
          </div>
        </main>
      )}
      renderFooter={(isMobileMenuOpen) => (
        <Footer className={classNames({ hidden: isMobileMenuOpen })} />
      )}
    ></Layout>
  )
}

export default AppLayout
