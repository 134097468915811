'use client'

import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { Layout } from 'app/components/layouts'
import AppLayout from 'app/components/layouts/AppLayout'
import HomeCoverImage from 'public/home-cover.png'
import HomeCoverSmImage from 'public/home-cover-sm.png'

interface LayoutProps {
  children: React.ReactNode
}

const HomeLayout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const searchParams = useSearchParams()
  const tripla_booking_widget_open = searchParams?.get(
    'tripla_booking_widget_open',
  )
  const isUsePageLayout = !tripla_booking_widget_open

  if (!isUsePageLayout) {
    return <Layout>{children}</Layout>
  }

  return (
    <AppLayout
      renderCover={() => (
        <>
          <div className="hidden md:block relative h-102 w-full bg-gray-100">
            <Image
              alt=""
              src={HomeCoverImage}
              priority
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
              }}
            />
            <div className="left-0 top-0 absolute block h-full w-full bg-gradient-to-t from-gray-550"></div>
          </div>
          <div className="md:hidden relative h-98 w-full">
            <Image
              alt=""
              src={HomeCoverSmImage}
              priority
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
              }}
            />
          </div>
        </>
      )}
      mainClassName="-mt-30 lg:-mt-29"
    >
      {children}
    </AppLayout>
  )
}

export default HomeLayout
